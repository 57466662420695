/* CACHER LES OPTIONS DE LISTE VIDES PAR DEFAUT */

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-15nsl9c-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper > ul > li[data-value="empty"] {
    pointer-events: none;
    padding: 0;
    height: 0;
}

p {
    margin-top: auto !important;
}

/* .css-1d00q76-MuiToolbar-root-RaListToolbar-root {
    min-height: auto !important;
    height: 35px !important;
} */