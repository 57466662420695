/* ########################################################## */
/*  VARIABLES */

$color-gray : #59626B;
$color-lightgray: #EFEFEF;
$color-blue : #0261BF;

/* ########################################################## */
/* MODAL */

/* MODAL STATUS */
// .modal-photos {
//     max-width: 40% !important;
// }

.modal-photos {
    --bs-modal-width: 800px;
}

.modal-photos.extended {
    max-width: 100% !important;
}

/* PLACEMENT DE LA MODAL VERS LA GAUCHE */

.modal-dialog {
    padding: 0 50px;
    //margin-left: 50px !important;
    //margin-right: 50px !important;
}

/* ########################################################## */
/* BOUTONS ET NAVIGATION*/

#nav {
    border-right: 1px solid $color-lightgray;
}

.button-link {
    text-transform: none !important;
    color: $color-gray !important;
    margin-right: 16px !important;

    &:hover {
        text-decoration: underline !important;
        color: $color-blue !important;
        background-color: transparent !important;
    }

    &:after {
        box-shadow: none !important;
    }
}

/* ########################################################## */
/* CATALOGUE D'IMAGES */

.filter-icon {
    padding: 6px !important;
    border: 1px solid $color-lightgray !important;
    border-radius: 10px !important;
}

.list-item { border: 3px solid white; border-radius: 15px;}
.list-item-bar { border-radius: 0 0 15px 15px;}
.list-item-img { border-radius: 15px; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);}

.list-item:hover,
.list-item-active {
    border: 3px solid #007BFF;
    cursor: pointer;
    border-radius: 18px;
}

#select-categories {
    width: 100% !important;
    height: 10px !important;
    padding: 10px !important;

    background-color: #4f3cc9 !important;
    color: white !important;
    
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 0 10px 10px 0 !important;

    text-transform: uppercase !important;
    letter-spacing: 0.02857em !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
}

.select-categories-label {
    text-transform: uppercase !important;
    color: white !important;
    letter-spacing: 0.02857em !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
}

.css-uai067-MuiPaper-root {
    border-radius: 10px 0 0 10px !important;
}
