.RaRichTextInput-editorContent {
	[data-more="true"] {
		border-bottom: 1px dashed gray;
		margin: 20px 0;
		opacity: .5;

		&::before {
			content: 'Lire la suite';
			text-align: center;
			display: block;
			font-size: 14px;
		}
	}
}
